import { HttpErrorResponse } from '@angular/common/http';
import { AfterContentChecked, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTooltip } from '@angular/material/tooltip';
import * as moment from 'moment';
import { filter, takeWhile } from 'rxjs/operators';
import { Constants } from 'src/app/order/constants/constants';
import { DocumentCategory } from 'src/app/order/enums/document-category.enum';
import { DateMask } from 'src/app/order/masks/date-mask';
import { cpfCnpjMask } from 'src/app/order/masks/document-masks';
import { PhoneMask } from 'src/app/order/masks/phone-mask';
import { RneMask } from 'src/app/order/masks/rne-mask';
import { Messages } from 'src/app/order/messages/order.messages';
import { orderRoutingDefinitions } from 'src/app/order/routing/routing-definitions';
import { BlackListService } from 'src/app/order/services/external/black-list/black-list.service';
import { ClientService } from 'src/app/order/services/external/client-validation/client-validation.service';
import { NationalityService } from 'src/app/order/services/external/nationality/nationality.service';
import { ProposalService } from 'src/app/order/services/external/proposal/proposal.service';
import { SerproService } from 'src/app/order/services/external/serpro/serpro.service';
import { DialogService } from 'src/app/order/services/internal/dialog/dialog.service';
import { ErrorService } from 'src/app/order/services/internal/error/error.service';
import { LoadingService } from 'src/app/order/services/internal/loading/loading.service';
import { RoutingService } from 'src/app/order/services/internal/routing/routing.service';
import { ScrollService } from 'src/app/order/services/internal/scroll/scroll.service';
import { WizardService } from 'src/app/order/services/internal/wizard/wizard.service';
import { DataStoreService } from 'src/app/order/store/data-store.service';
import { DataStore } from 'src/app/order/store/data.store';
import { getConstitutionFormByCode } from 'src/app/order/utils/constitution-form';
import { dateValidator } from 'src/app/order/validators/date-validator';
import { NameValidator } from 'src/app/order/validators/name-validator';
import { RequiredIfValidator } from 'src/app/order/validators/required-if-validator';
import { InputType } from 'src/app/shared/fd-form-components/fd-input/fd-input.component';
import { FdSelectConfig, Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { DeliveryContactModel, Proposal, ReceivingDataModel, RegistrationDataPersonalInfoModel } from 'src/app/shared/models/proposal';
import { CnaeModel } from 'src/app/shared/models/response/response-cnae.model';
import { FdFieldConfig, FdFieldConfigs } from 'src/app/shared/shared-components.module';
import { ConfigurationModel, SerproValidationStrategyEnum } from 'src/app/start/models/configuration/configuration.model';
import { ConfigurationService } from 'src/app/start/services/configuration.service';
import { BlackListModel } from '../../../../shared/models/BlackList.model';
import { FieldTypeBlacklistEnum } from '../../../../shared/models/response/response-black-list.model';
import { EmailRegExp } from '../../../validators/email-compare-validator';
import { FormStep } from '../../form-step';
import { ResponseClientSerproV2CnpjModel } from '../../proposal-data/partner-data/models/response-client-serpro-v2-cnpj.model';
import { SerproCnpjValidationRequestModel } from './models/serpro-cnpj-validation-request.model';
import { SerproCpfRequestModelV2 } from './models/serpro-cpf-request.model';
import { customPersonalDataValidators } from './registration-data-personal-info.validators';

@Component({
  selector: 'app-registration-data-personal-info',
  templateUrl: './registration-data-personal-info.component.html',
  styleUrls: ['./registration-data-personal-info.component.scss'],
})
export class RegistrationDataPersonalInfoComponent extends FormStep implements OnInit, AfterContentChecked {
  get isCNPJ(): boolean {
    return this.documentCategory === DocumentCategory.CNPJ;
  }

  get isCPF(): boolean {
    return this.documentCategory === DocumentCategory.CPF;
  }

  get complianceDataEnabled(): boolean {
    return this.configuration && this.configuration.ableComplianceData;
  }

  get motherDataEnabled() {
    return this.configuration && this.configuration.ableMotherName;
  }

  get agentCpfCnpj() {
    const userData = this.dataStoreService.getUserData();
    return userData.cpfCnpj;
  }

  get formControls(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }

  public showPartnerField: boolean;

  public registrationDataPersonalInfoModel: RegistrationDataPersonalInfoModel;

  public fields: FdFieldConfigs;
  public isPepEnable = false;
  public lastCpfCnpjValue: string;
  public onlyECommerce = false;
  public constitutionFormCode: string;
  public successDocumentCall = false;
  public documentCategory: DocumentCategory;
  public SOURCE_FIXED_DOCUMENT: string;
  public rneEnabled = false;
  public CURRENT_DOCUMENT_CALL_TRY = 0;
  public cnaeList: CnaeModel[] = [];
  public nationalityList: Item[];
  public isExceptionFlow = false;

  @ViewChild('tooltip') public tooltip: MatTooltip;

  protected STEP_NUMBER = 1;

  private readonly CPF_REGEX = /^[0-9]{11}$/;
  private readonly ECOMMERCE_TECHNOLOGY_GROUP = 'COM';
  private readonly CNPJ_REGEX = /^[0-9]{14}$/;
  private readonly MAX_DOCUMENT_CALL_LIMIT = 3;

  constructor(
    private formBuilder: FormBuilder,
    private serproService: SerproService,
    private blackListService: BlackListService,
    private clientService: ClientService,
    protected proposalService: ProposalService,
    protected loadingService: LoadingService,
    protected dialogService: DialogService,
    protected dataStore: DataStore,
    protected scrollService: ScrollService,
    protected wizardService: WizardService,
    protected matDialog: MatDialog,
    protected configurationService: ConfigurationService,
    protected routingService: RoutingService,
    protected dataStoreService: DataStoreService,
    private errorService: ErrorService,
    private nationalityService: NationalityService,
    private cdref: ChangeDetectorRef
  ) {
    super(
      dataStore,
      scrollService,
      wizardService,
      matDialog,
      proposalService,
      loadingService,
      dialogService,
      configurationService,
      routingService,
      dataStoreService
    );
  }

  public checkSerproConfiguration() {
    if (this.configuration && this.configuration.serproValidationStrategy === SerproValidationStrategyEnum.PERSONAL_INFO_PAGE) {
      this.isSerproSituationValid();
    } else {
      this.preValidateFields();
      this.next();
    }
  }

  public showTooltipAndStopPropagation() {
    this.tooltip.toggle();
    event.stopImmediatePropagation();
  }

  public getPepTooltipText() {
    return 'Pessoa Exposta Politicamente (PEP), é aquela que desempenha ou desempenhou nos últimos 5 anos,\
    no Brasil ou no exterior, cargo, emprego ou função pública relevante, incluindo políticos (eleitos ou candidatos)\
    e dirigentes de partidos políticos, assim como seus representantes, familiares e outras pessoas de seu relacionamento próximo.';
  }

  public isSerproSituationValid() {
    if (!this.preValidateFields()) {
      return;
    }

    if (this.documentCategory === DocumentCategory.CNPJ) {
      this.validateCnpj();
      return;
    }

    this.serproService
      .validateSituation(this.formControls.cpfCnpj.value, this.proposal.institution, this.proposal.serviceContract)
      .pipe(takeWhile(() => this.alive))
      .subscribe(
        (response) => {
          this.handleResponse(response, () => this.checkValidationFn());
        },
        (error) => {
          this.dialogService.openDialog(Messages.SERPRO_VALIDATION_ERROR);
          return false;
        }
      );
  }

  private preValidateFields(): boolean {
    this.validatePoliticalExposition();
    this.validateNationality();

    if (!this.formControls.politicalExposition.value) {
      this.formControls.pepName.setErrors(null);
      this.formControls.pepRole.setErrors(null);
      this.formControls.pepGovernmentOrganization.setErrors(null);
    }

    if (this.documentCategory === DocumentCategory.CPF) {
      this.formControls.personFantasyName.setValue(this.formControls.personFantasyName.value.trim());
      this.formControls.name.setValue(this.formControls.name.value.trim());
    }

    this.clearErrors();
    if (!this.isFormGroupValid()) {
      return false;
    }

    return true;
  }

  private validatePoliticalExposition(): void {
    if (this.politicalExpositionHasNoValue() && this.isCPF) {
      this.formControls.politicalExposition.setErrors({ required: true });
    } else {
      this.formControls.politicalExposition.setErrors(null);
    }
  }

  private validateNationality(): void {
    if (this.nationalityHasNoValue() && this.isCPF) {
      this.formControls.nationality.setErrors({ required: true });
    } else {
      this.formControls.nationality.setErrors(null);
    }
  }

  private nationalityHasNoValue(): boolean {
    return !this.formControls.nationality.value;
  }

  private politicalExpositionHasNoValue(): boolean {
    return (
      this.formControls.politicalExposition.value === null ||
      this.formControls.politicalExposition.value === undefined ||
      this.formControls.politicalExposition.value === ''
    );
  }

  public checkValidationFn() {
    if (this.isCPF) {
      this.validateCpf();
    } else if (this.isCNPJ) {
      this.validateCnpj();
    }
  }

  public validateCpf() {
    var date =
      this.formControls.birthDate.value.slice(4, 8) +
      '-' +
      this.formControls.birthDate.value.slice(2, 4) +
      '-' +
      this.formControls.birthDate.value.slice(0, 2);

    const model: SerproCpfRequestModelV2 = {
      dataNascimento: date,
      cpf: this.formControls.cpfCnpj.value,
      isPep: this.isPepEnable,
      nome: this.formControls.name.value,
    };

    this.serproService
      .validateCpf(model)
      .pipe(takeWhile(() => this.alive))
      .subscribe(
        (response) => {
          this.handleResponse(response, () => {
            localStorage.setItem(Constants.SERPRO_SUCCESSFUL_DOCUMENTS_SESSIONSTORAGE_KEY, model.cpf);
            this.next();
          });
        },
        (error) => {
          this.dialogService.openDialog(Messages.SERPRO_VALIDATION_ERROR);
          return false;
        }
      );
  }

  public validateMinimumAge() {
    this.serproService
      .validateMinimumAge(this.formControls.cpfCnpj.value, this.formControls.birthDate.value.replace(/\//g, ''))

      .pipe(takeWhile(() => this.alive))
      .subscribe(
        (isValid) => {
          if (!isValid) {
            this.dialogService.openDialog(Messages.INVALID_MINIMUM_AGE);
            return;
          }
          this.next();
        },
        (error) => {
          this.dialogService.openDialog(Messages.SERPRO_VALIDATION_ERROR);
          return false;
        }
      );
  }

  public handleResponse(response: any, nextAction: () => any) {
    if (!response) {
      this.dialogService.openDialog(Messages.SERPRO_VALIDATION_ERROR);
      return;
    }
    if (response.situacao) {
      nextAction();
      return;
    }
    if (!response.situacao && response.inconsistencias && response.inconsistencias.length) {
      this.dialogService.openDialogWithMessage(response.inconsistencias[0].descricao);
      return;
    }
  }

  public validateCnpj() {
    const startDate = moment(this.formControls.openDate.value, 'DDMMYYYY');
    const currenDate = moment(new Date()).format('DD/MM/YYYY');
    const endDate = moment(currenDate, 'DD/MM/YYYY');
    const days = endDate.diff(startDate, 'days');

    if (!days) {
      this.dialogService.openDialog(Messages.OPEN_DATE_LEGAL_PERSON_ERROR);
      return;
    }

    const model: SerproCnpjValidationRequestModel = {
      cnpj: this.formControls.cpfCnpj.value,
      codigoContrato: this.serviceContract.toString(),
      instituicao: this.institution,
      dataFundacao: moment(this.formControls.openDate.value, 'DDMMYYYY').format('DD-MM-YYYY'),
      razaoSocial: this.formControls.socialReason.value,
    };
    localStorage.getItem;
    this.serproService
      .validateCnpj(model)

      .pipe(takeWhile(() => this.alive))
      .subscribe(
        (response) => {
          this.handleResponse(response, () => {
            localStorage.setItem(Constants.SERPRO_SUCCESSFUL_DOCUMENTS_SESSIONSTORAGE_KEY, model.cnpj);
            this.next();
          });
        },
        (error) => {
          this.dialogService.openDialog(Messages.SERPRO_VALIDATION_ERROR);
          return false;
        }
      );
  }

  public redirectToHome() {
    this.routingService.navigateToHome(false);
  }

  public async ngOnInit() {
    await super.ngOnInit();
    this.validadeRadioPreviousPage();
    this.configurationService
      .getConfiguration()

      .pipe(takeWhile(() => this.alive))
      .subscribe(
        (response) => {
          if (response) {
            if (!response.ableMdrBillingCharge && !response.ableMdrFlexBillingCharge) {
              this.dialogService.openDialog(Messages.MDR_OR_MDR_FLEX_CONFIG_NOT_SET, () => this.routingService.navigateToHome());
              return;
            }

            this.configurationService.setConfigurationOnSessionStorage(response);
            this.configuration = this.configurationService.getConfigurationFromSessionStorage();
            return;
          }

          this.dialogService.openErrorDialog(
            Messages.FAILED_TO_GET_PROPOSAL_DATA,
            'Api Fiserv Online - Registro',
            () => this.routingService.navigateToHome(),
            null,
            'error'
          );
        },
        (err: HttpErrorResponse) => {
          if (err.status === 404) {
            this.dialogService.openDialog(Messages.SERVICE_CONTRACT_CONFIG_NOT_SET, () => this.routingService.navigateToHome());
            return;
          }
          this.dialogService.openErrorDialog(
            Messages.FAILED_TO_GET_PROPOSAL_DATA,
            'Api Fiserv Online - Registro',
            () => this.routingService.navigateToHome(),
            null,
            'error'
          );
        }
      );

    this.setConditionalValidators();
    this.checkConfigurations();
    this.getNationalityList();
    this.nationalityChange();
  }

  public ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  public checkConfigurations() {
    this.configurationService.getConfiguration().subscribe(
      (response) => {
        if (!response) {
          this.dialogService.openDialog(Messages.CONFIG_PROPOSAL_LOAD_FAIL);
          return;
        }
        let cfg = new ConfigurationModel();
        cfg = response;
        this.showPartnerField = cfg.partnerId;
        this.setConditionalValidators();
        this.isExceptionFlow = cfg.ableExceptionFlow;
      },
      (error: HttpErrorResponse) => this.errorService.handleXHRError(error, Messages.CONFIG_PROFESSIONAL_LOAD_FAIL)
    );
  }

  public setConditionalValidators() {
    if (this.isPepEnable) {
      this.formControls.pepName.setValidators([RequiredIfValidator(() => this.isPepEnable), NameValidator]);
      this.formControls.pepRole.setValidators([RequiredIfValidator(() => this.isPepEnable)]);
      this.formControls.pepGovernmentOrganization.setValidators([RequiredIfValidator(() => this.isPepEnable)]);
      this.formControls.partnerId.setValidators([RequiredIfValidator(() => this.showPartnerField)]);
    } else {
      this.formControls.pepName.setValue('');
      this.formControls.pepRole.setValue('');
      this.formControls.pepGovernmentOrganization.setValue('');
      this.formControls.pepName.setErrors(null);
      this.formControls.pepRole.setErrors(null);
      this.formControls.pepGovernmentOrganization.setErrors(null);
    }
  }

  public setFieldsState(enable: boolean) {
    if (enable) {
      if (this.isCNPJ) {
        this.formControls.socialReason.enable();
        this.formControls.constitutionForm.enable();
        this.formControls.companyFantasyName.enable();
        this.formControls.openDate.enable();
        this.formControls.companyEmail.enable();
        this.formControls.companyMobileNumber.enable();
      } else if (this.isCPF) {
        this.formControls.name.enable();
        this.formControls.motherName.enable();
        this.formControls.personFantasyName.enable();
        this.formControls.birthDate.enable();
        this.formControls.personEmail.enable();
        this.formControls.personMobileNumber.enable();
        this.formControls.fixedNumberOne.enable();
        this.formControls.fixedNumberTwo.enable();
        this.formControls.siteAddress.enable();
        this.formControls.politicalExposition.enable();
        this.formControls.nationality.enable();
        this.formControls.rne.enable();
      }
    } else {
      if (this.isCNPJ) {
        this.formControls.socialReason.disable();
        this.formControls.companyFantasyName.disable();
        this.formControls.openDate.disable();
        this.formControls.constitutionForm.disable();
        this.formControls.companyEmail.disable();
        this.formControls.companyMobileNumber.disable();
      } else if (this.documentCategory) {
        this.formControls.name.disable();
        this.formControls.personFantasyName.disable();
        this.formControls.motherName.disable();
        this.formControls.birthDate.disable();
        this.formControls.personEmail.disable();
        this.formControls.fixedNumberOne.disable();
        this.formControls.fixedNumberTwo.disable();
        this.formControls.siteAddress.disable();
        this.formControls.personMobileNumber.disable();
        this.formControls.politicalExposition.disable();
        this.formControls.nationality.disable();
        this.formControls.rne.disable();
      }
    }
  }

  public setAndCheckEmpty(control: AbstractControl, data: string) {
    if (data) {
      control.setValue(data);
      control.disable();
    } else {
      control.enable();
    }
  }

  public clearFieldsValues() {
    this.formControls.socialReason.setValue('');
    this.formControls.companyFantasyName.setValue('');
    this.formControls.constitutionForm.setValue('');
    this.constitutionFormCode = '';
    this.formControls.personFantasyName.setValue('');
    this.formControls.openDate.setValue('');
    this.formControls.companyEmail.setValue('');
    this.formControls.companyMobileNumber.setValue('');
    this.formControls.name.setValue('');
    this.formControls.birthDate.setValue('');
    this.formControls.motherName.setValue('');
    this.formControls.personEmail.setValue('');
    this.formControls.fixedNumberOne.setValue('');
    this.formControls.fixedNumberTwo.setValue('');
    this.formControls.siteAddress.setValue('');
    this.formControls.personMobileNumber.setValue('');
    this.formControls.politicalExposition.setValue('');
    this.formControls.nationality.setValue('');
    this.formControls.rne.setValue('');
    this.formControls.notesCreditRisk.setValue('');
  }

  public setAndClearValidators(documentCategory: DocumentCategory) {
    if (documentCategory === DocumentCategory.CPF) {
      this.formControls.name.setValidators(customPersonalDataValidators(this.documentCategory).name.validators);
      this.formControls.birthDate.setValidators(customPersonalDataValidators(this.documentCategory).birthDate.validators);
      this.formControls.personEmail.setValidators(customPersonalDataValidators(this.documentCategory).personEmail.validators);
      this.formControls.personMobileNumber.setValidators(customPersonalDataValidators(this.documentCategory).personMobileNumber.validators);
      this.formControls.fixedNumberOne.setValidators(customPersonalDataValidators(this.documentCategory).fixedNumberOne.validators);
      this.formControls.fixedNumberTwo.setValidators(customPersonalDataValidators(this.documentCategory).fixedNumberTwo.validators);
      this.formControls.personFantasyName.setValidators(customPersonalDataValidators(this.documentCategory).personFantasyName.validators);
      this.formControls.nationality.setValidators(customPersonalDataValidators(this.documentCategory).nationality.validators);
      this.formControls.rne.setValidators(customPersonalDataValidators(this.documentCategory).rne.validators);
      this.formControls.motherName.setValidators(
        customPersonalDataValidators(this.documentCategory, this.motherDataEnabled).motherName.validators
      );
      this.formControls.personFantasyName.markAsUntouched();
      this.formControls.name.markAsUntouched();
      this.formControls.motherName.markAsUntouched();
      this.formControls.siteAddress.markAsUntouched();
      this.formControls.fixedNumberOne.markAsUntouched();
      this.formControls.fixedNumberTwo.markAsUntouched();
      this.formControls.birthDate.markAsUntouched();
      this.formControls.personEmail.markAsUntouched();
      this.formControls.personMobileNumber.markAsUntouched();
      this.formControls.nationality.markAsUntouched();
      this.formControls.rne.markAsUntouched();

      this.formControls.socialReason.clearValidators();
      this.formControls.constitutionForm.clearValidators();
      this.formControls.companyFantasyName.clearValidators();
      this.formControls.openDate.clearValidators();
      this.formControls.companyEmail.clearValidators();
      this.formControls.companyMobileNumber.clearValidators();
    } else if (documentCategory === DocumentCategory.CNPJ) {
      this.formControls.socialReason.setValidators(customPersonalDataValidators(this.documentCategory).socialReason.validators);
      this.formControls.companyFantasyName.setValidators(customPersonalDataValidators(this.documentCategory).companyFantasyName.validators);
      this.formControls.constitutionForm.setValidators(
        customPersonalDataValidators(this.documentCategory, this.complianceDataEnabled).constitutionForm.validators
      );
      this.formControls.openDate.setValidators([Validators.required, dateValidator()]);
      this.formControls.companyEmail.setValidators(customPersonalDataValidators(this.documentCategory).companyEmail.validators);
      this.formControls.companyMobileNumber.setValidators(
        customPersonalDataValidators(this.documentCategory).companyMobileNumber.validators
      );
      this.formControls.socialReason.markAsUntouched();
      this.formControls.companyFantasyName.markAsUntouched();
      this.formControls.constitutionForm.markAsUntouched();
      this.formControls.openDate.markAsUntouched();
      this.formControls.companyEmail.markAsUntouched();
      this.formControls.companyMobileNumber.markAsUntouched();

      this.formControls.name.clearValidators();
      this.formControls.personFantasyName.clearValidators();
      this.formControls.birthDate.clearValidators();
      this.formControls.motherName.clearValidators();
      this.formControls.fixedNumberOne.clearValidators();
      this.formControls.fixedNumberTwo.clearValidators();
      this.formControls.personEmail.clearValidators();
      this.formControls.personMobileNumber.clearValidators();
      this.formControls.nationality.clearValidators();
      this.formControls.rne.clearValidators();
    }
  }

  public clearErrors() {
    if (this.documentCategory === DocumentCategory.CPF) {
      this.formControls.socialReason.setErrors(null);
      this.formControls.constitutionForm.setErrors(null);
      this.formControls.companyFantasyName.setErrors(null);
      this.formControls.openDate.setErrors(null);
      this.formControls.companyEmail.setErrors(null);
      this.formControls.companyMobileNumber.setErrors(null);
    } else if (this.documentCategory === DocumentCategory.CNPJ) {
      this.formControls.name.setErrors(null);
      this.formControls.personFantasyName.setErrors(null);
      this.formControls.birthDate.setErrors(null);
      this.formControls.motherName.setErrors(null);
      this.formControls.fixedNumberOne.setErrors(null);
      this.formControls.fixedNumberTwo.setErrors(null);
      this.formControls.personEmail.setErrors(null);
      this.formControls.personMobileNumber.setErrors(null);
      this.formControls.nationality.setErrors(null);
      this.formControls.rne.setErrors(null);
    }
  }

  public handleError(error: any, serviceKey: string) {
    if (!error.status) {
      this.dialogService.openDialog(Messages.GENERAL_ERROR);
      console.error(`[${serviceKey}] - Unspecified error when querying CPF/CNPJ`);
    } else if (error.status === 400) {
      if (error.error.message) {
        this.dialogService.openDialog(new Messages('Atenção', error.error.message), this.redirectToHome.bind(this));
      } else {
        this.dialogService.openDialog(Messages.CPF_CNPJ_NOT_AUTHORIZED, this.redirectToHome.bind(this));
      }
    } else if (error.status === 404) {
      this.dialogService.openDialog(Messages.INVALID_CPF_CNPJ);
      console.error(`[${serviceKey}] - CPF/CNPJ not found`);
    } else {
      this.dialogService.openDialog(Messages.GENERAL_ERROR);
    }
  }

  public canProceed(response: ResponseClientSerproV2CnpjModel): boolean {
    if (Number(response.codigoRetorno)) {
      this.dialogService.openDialog(Messages.SERPRO_CNPJ_INFORMATION_ERROR, this.redirectToHome.bind(this));
      return false;
    }
    return true;
  }

  public checkCpfOnSocialReason(socialReason: string) {
    const match = socialReason?.match(/[0-9]{11}/);
    if (match) {
      socialReason = socialReason.replace(match[0], '');
    }
    return socialReason;
  }

  public callSerpro(document: string) {
    if (this.isCpf(document)) {
      this.setCpfFormData();
    } else if (this.isCnpj(document)) {
      if (this.configuration && this.configuration.serproValidationStrategy === SerproValidationStrategyEnum.PERSONAL_INFO_PAGE) {
        this.serproService
          .getCnpjInformationV2(document)
          .pipe(takeWhile(() => this.alive))
          .subscribe(
            (response) => {
              if (this.canProceed(response)) {
                if (response.ni) {
                  this.setAndCheckEmpty(this.formControls.socialReason, this.checkCpfOnSocialReason(response.nomeEmpresarial));
                  this.setAndCheckEmpty(this.formControls.companyFantasyName, response.nomeFantasia);
                  this.setAndCheckEmpty(this.formControls.openDate, response.dataAbertura);
                  this.setAndCheckEmpty(this.formControls.constitutionForm, response.naturezaJuridica.descricao);
                  this.constitutionFormCode = response.naturezaJuridica.codigo;
                  this.formControls.companyEmail.setValue(response.email);

                  this.documentCategory = DocumentCategory.CNPJ;
                  this.formControls.useAsDeliveryContact.setValue(false);
                  this.formControls.useAsReceivingData.setValue(false);

                  this.cnaeList = [];

                  if (response.cnaePrincipal) {
                    const arr = [];
                    arr.push(response.cnaePrincipal);
                    this.cnaeList.push(...arr);
                  }
                  if (response.cnaeSecundarias) {
                    this.cnaeList.push(...response.cnaeSecundarias);
                  }

                  this.setAndClearValidators(this.documentCategory);
                  this.successDocumentCall = true;
                } else if (this.isCpf(document)) {
                  this.setCpfFormData();
                } else {
                  this.setCnpjFormData();
                }
              }
            },
            (error) => {
              this.handleError(error, 'Serpro');
              this.clearFieldsValues();
            }
          );
      } else {
        this.setCnpjFormData();
      }
    }
  }

  private setCpfFormData() {
    this.documentCategory = DocumentCategory.CPF;
    this.formControls.useAsDeliveryContact.setValue(true);
    this.formControls.useAsReceivingData.setValue(true);
    this.setAndClearValidators(this.documentCategory);
    this.successDocumentCall = true;
  }

  private setCnpjFormData() {
    this.documentCategory = DocumentCategory.CNPJ;
    this.formControls.useAsDeliveryContact.setValue(false);
    this.formControls.useAsReceivingData.setValue(false);
    this.setAndClearValidators(this.documentCategory);
    this.successDocumentCall = true;
  }

  private isCpf(document: string) {
    return document && document.length === 11 && this.CPF_REGEX.test(document);
  }

  private isCnpj(document: string) {
    return document && document.length === 14 && this.CNPJ_REGEX.test(document);
  }

  public callBlackList(field: string, value: string, fieldName: string) {
    const blackListModel = new BlackListModel(this.institution, this.serviceContract.toString(), value, field);
    this.blackListService
      .validateBlackList(blackListModel)
      .pipe(takeWhile(() => this.alive))

      .subscribe(
        (isInBlacklist) => {
          if (!isInBlacklist) {
            return;
          }
          if (field === '2') {
            this.dialogService.openDialog(Messages.PHONE_NOT_AUTHORIZED);
          }
          if (field === '3') {
            this.dialogService.openDialog(Messages.EMAIL_NOT_AUTHORIZED);
          }
          this.formControls[fieldName].setValue('');

          this.callSerpro(value);
        },
        (error) => {
          this.dialogService.openDialog(Messages.GENERAL_ERROR_BLACKLIST_API);
          this.formControls[fieldName].setValue('');
          console.error('[Blacklist] - Unspecified error');
        }
      );
  }

  public callBlackListCpf(field: string, value: string, fieldName: string) {
    const blackListModel = new BlackListModel(this.institution, this.serviceContract.toString(), value, field);
    this.blackListService
      .validateBlackList(blackListModel)
      .pipe(takeWhile(() => this.alive))
      .subscribe(
        (isInBlacklist) => {
          if (!isInBlacklist) {
            if (this.isCPF) {
            }

            this.callSerpro(value);
            return;
          }
          this.dialogService.openDialog(Messages.CPF_CNPJ_NOT_AUTHORIZED, this.redirectToHome.bind(this));
        },
        (error) => {
          this.dialogService.openDialog(Messages.GENERAL_ERROR_BLACKLIST_API);
          this.formControls[fieldName].setValue('');
          console.error('[Blacklist] - Unspecified error');
        }
      );
  }

  public callValidateByTechnology(document) {
    this.clientService
      .validateTechnologyGroup(document, this.institution, this.ECOMMERCE_TECHNOLOGY_GROUP)
      .pipe(takeWhile(() => this.alive))
      .subscribe(
        (response) => {
          if (response) {
            this.dialogService.openConfirmDialog(
              Messages.ONLY_ECOMMERCE_PROPOSAL,
              () => {
                this.callBlackListCpf(FieldTypeBlacklistEnum.DOCUMENT, document, 'cpfCnpj');
                this.onlyECommerce = true;
              },
              () => this.navigateToHome()
            );
          } else {
            this.dialogService.openDialog(Messages.CPF_CNPJ_NOT_AUTHORIZED, () => this.redirectToHome());
          }
        },
        (error) => {
          this.dialogService.openDialog(Messages.GENERAL_ERROR);
          console.error('[Client Validation] - Unspecified error when querying CPF/CNPJ');
        }
      );
  }

  public callValidateByHierarchyChannel(document) {
    this.clientService
      .validateByHierarchyChannel(document, this.dataStoreService.getUserDataSubChannel(this.userData))
      .pipe(takeWhile(() => this.alive))
      .subscribe(
        (response) => {
          if (!response) {
            this.dialogService.openDialog(Messages.CPF_CNPJ_NOT_AUTHORIZED_CHANNEL_SIPAG, () => this.redirectToHome());
          }
        },
        (error) => {
          this.dialogService.openDialog(Messages.GENERAL_ERROR);
          console.error('[Client Hierarchy Validation] - Unspecified error when querying CPF/CNPJ');
        }
      );
  }

  public setDocumentCategory(document: string) {
    this.CNPJ_REGEX.test(document)
      ? (this.documentCategory = DocumentCategory.CNPJ)
      : this.CPF_REGEX.test(document)
      ? (this.documentCategory = DocumentCategory.CPF)
      : // tslint:disable-next-line: no-unused-expression
        null;
  }

  public isEmpty(str) {
    return !str || str.length === 0;
  }

  public emailChange(email: string, fieldName: string) {
    if (!this.isEmpty(email) && email.match(EmailRegExp)) {
      this.callBlackList(FieldTypeBlacklistEnum.EMAIL, email, fieldName);
    }
  }

  public phoneChange(phone: string, fieldName: string) {
    const phoneNotMask = phone.replace(/[-( )]/g, '');
    if (!this.isEmpty(phone) && (phoneNotMask.length === 11 || phoneNotMask.length === 10)) {
      this.callBlackList(FieldTypeBlacklistEnum.PHONE, phone, fieldName);
    }
  }

  public notesChange(notes: string) {
    this.formControls.notesCreditRisk.setValue(
      notes
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/[^\w\s]/gi, '')
    );
  }

  public nationalityChange() {
    this.formControls.nationality.valueChanges.pipe(filter((x) => !!x)).subscribe((nationality) => {
      if (!this.isEmpty(nationality) && nationality != 'Brasileiro') {
        this.rneEnabled = true;
        this.formControls.rne.setValidators([Validators.required]);
      } else {
        this.rneEnabled = false;
        this.formControls.rne.setValue('');
        this.formControls.rne.clearValidators();
      }
    });
  }

  public cpfCnpjChange(document: string) {
    const userData = this.dataStoreService.getUserData();
    this.setDocumentCategory(document);
    if ((this.CPF_REGEX.test(document) || this.CNPJ_REGEX.test(document)) && this.formControls.cpfCnpj.valid) {
      this.SOURCE_FIXED_DOCUMENT = document;

      this.clientService
        .validateIfClientExists(document, this.institution)
        .pipe(takeWhile(() => this.alive))
        .subscribe(
          (clientDoesntExist) => {
            if (clientDoesntExist) {
              this.callBlackListCpf(FieldTypeBlacklistEnum.DOCUMENT, document, 'cpfCnpj');
              return;
            } else {
              if (this.dataStoreService.isBinServiceContractProposal(this.proposal.serviceContract)) {
                this.callValidateByHierarchyChannel(document);
                this.callSerpro(document);
              } else {
                this.callValidateByTechnology(document);
              }
            }
          },
          (error) => {
            this.dialogService.openDialog(Messages.GENERAL_ERROR);
            console.error('[Client Validation] - Unspecified error when querying CPF/CNPJ');
          }
        );
    }
  }

  public getNationalityList() {
    this.nationalityService
      .getNationalityList()
      .pipe(takeWhile(() => this.alive))
      .subscribe(
        (response) => {
          if (response !== null) {
            this.nationalityList = response;
            (this.fields.nationality as FdSelectConfig).items = [];
            this.listNationality('', null);
          }
        },
        (error) => {
          this.dialogService.openErrorDialog(
            Messages.FAILED_TO_GET_BANK_LIST,
            'Api Config - Nacionalidade',
            () => this.wizardService.previousStep(),
            null,
            'error'
          );
          (this.fields.nationality as FdSelectConfig).items = [];
        }
      );
  }

  public listNationality(text: string = '', field: FdFieldConfig) {
    if (!this.nationalityList || !this.nationalityList.length) {
      return;
    }

    if (field) {
      (field as FdSelectConfig).items = [];

      const itemsToAdd = this.nationalityList
        .filter((item) =>
          item.label
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
            .match(text.toLowerCase())
        )
        .map((value) => ({
          label: value.label,
          value: value.value,
        }));

      (field as FdSelectConfig).items.push(...itemsToAdd);
    } else {
      (this.fields.nationality as FdSelectConfig).items = [];

      const itemsToAdd = this.nationalityList
        .filter((item) => item.label)
        .map((value) => ({
          label: value.label,
          value: value.value,
        }));

      (this.fields.nationality as FdSelectConfig).items.push(...itemsToAdd);
    }
  }

  public isSipag(serviceContract): boolean {
    return serviceContract === 104 || serviceContract === 105 || serviceContract === 106;
  }

  public pepEnabledForm(event) {
    this.isPepEnable = event;
    this.setConditionalValidators();
  }

  public validadeRadioPreviousPage() {
    if (this.registrationDataPersonalInfoModel) {
      this.isPepEnable = this.registrationDataPersonalInfoModel.politicalExposition;
    }
  }

  public navigateToHome() {
    this.routingService.navigateToHome();
  }

  public createFormGroup(): FormGroup {
    return this.formBuilder.group({
      cpfCnpj: ['', customPersonalDataValidators(this.documentCategory).cpfCnpj],
      socialReason: ['', customPersonalDataValidators(this.documentCategory).socialReason],
      companyFantasyName: ['', customPersonalDataValidators(this.documentCategory).companyFantasyName],
      personFantasyName: ['', customPersonalDataValidators(this.documentCategory).personFantasyName],
      openDate: ['', customPersonalDataValidators(this.documentCategory).openDate],
      name: ['', customPersonalDataValidators(this.documentCategory).name],
      birthDate: ['', customPersonalDataValidators(this.documentCategory).birthDate],
      motherName: ['', customPersonalDataValidators(this.documentCategory, this.motherDataEnabled).motherName],
      constitutionForm: ['', customPersonalDataValidators(this.documentCategory, this.complianceDataEnabled).constitutionForm],
      companyEmail: ['', customPersonalDataValidators(this.documentCategory).companyEmail],
      companyMobileNumber: ['', customPersonalDataValidators(this.documentCategory).companyMobileNumber],
      personEmail: ['', customPersonalDataValidators(this.documentCategory).personEmail],
      personMobileNumber: ['', customPersonalDataValidators(this.documentCategory).personMobileNumber],
      politicalExposition: ['', customPersonalDataValidators(this.documentCategory).politicalExposition],
      useAsDeliveryContact: ['', customPersonalDataValidators(this.documentCategory).useAsDeliveryContact],
      useAsReceivingData: ['', customPersonalDataValidators(this.documentCategory).useAsReceivingData],
      siteAddress: ['', customPersonalDataValidators(this.documentCategory).siteAddress],
      fixedNumberOne: ['', customPersonalDataValidators(this.documentCategory).fixedNumberOne],
      fixedNumberTwo: ['', customPersonalDataValidators(this.documentCategory).fixedNumberTwo],
      nationality: ['', customPersonalDataValidators(this.documentCategory).nationality],
      rne: ['', customPersonalDataValidators(this.documentCategory).rne],
      pepName: [''],
      pepRole: [''],
      pepGovernmentOrganization: [''],
      partnerId: [''],
      notesCreditRisk: [''],
    });
  }
  protected proposalGetCallback(): void {}

  protected async persistData() {
    if (!this.successDocumentCall) {
      return Promise.resolve(false);
    }

    this.formControls.cpfCnpj.setErrors(null);
    this.setAndClearValidators(this.documentCategory);

    if (this.isCPF && this.CNPJ_REGEX.test(this.formControls.cpfCnpj.value)) {
      this.formControls.cpfCnpj.setErrors({ invalidCpf: true });
      return Promise.resolve(false);
    } else if (this.isCNPJ && this.CPF_REGEX.test(this.formControls.cpfCnpj.value)) {
      this.formControls.cpfCnpj.setErrors({ invalidCnpj: true });
      return Promise.resolve(false);
    }

    const rawValue = this.formGroup.getRawValue();

    let registrationDataPersonalInfo = new RegistrationDataPersonalInfoModel();

    registrationDataPersonalInfo = {
      ...rawValue,
      successDocumentCall: this.successDocumentCall,
      cnaeList: this.cnaeList,
      documentCategory: this.documentCategory,
      onlyECommerce: this.onlyECommerce,
    };

    if (this.configuration && this.configuration.serproValidationStrategy === SerproValidationStrategyEnum.PERSONAL_INFO_PAGE) {
      registrationDataPersonalInfo.constitutionForm = getConstitutionFormByCode(this.constitutionFormCode);
      registrationDataPersonalInfo.constitutionFormCode = this.constitutionFormCode;
    }

    if (this.isPepEnable) {
      registrationDataPersonalInfo.politicallyExposedPerson = {
        name: this.formControls.pepName.value,
        governmentAgency: this.formControls.pepGovernmentOrganization.value,
        responsibility: this.formControls.pepRole.value,
      };
    }

    let deliveryContact = new DeliveryContactModel();
    let receivingDataModel = new ReceivingDataModel();

    if (this.isCPF) {
      if (this.formControls.useAsDeliveryContact && !!this.formControls.useAsDeliveryContact.value) {
        deliveryContact = {
          cpf: rawValue.cpfCnpj,
          contactName: rawValue.name,
          emailAddress: rawValue.personEmail,
          siteAddress: rawValue.siteAddress,
          fixedNumberRequired: rawValue.fixedNumberOne,
          fixedNumber: rawValue.fixedNumberTwo,
          mobileNumber: rawValue.personMobileNumber,
        };

        await this.dataStore.updateProposal({ deliveryContact });
      }

      if (this.formControls.useAsReceivingData && !!this.formControls.useAsReceivingData.value) {
        receivingDataModel = {
          name: rawValue.name,
          fixedNumber: rawValue.fixedNumberOne,
        };

        await this.dataStore.updateProposal({ receivingDataModel });
      }

      this.setStepJump(
        orderRoutingDefinitions.proposal.stepNumber,
        orderRoutingDefinitions.proposal.routes.DELIVERY_CONTACT.order,
        this.formControls.useAsDeliveryContact && !!this.formControls.useAsDeliveryContact.value
      );

      this.setStepJump(
        orderRoutingDefinitions.proposal.stepNumber,
        orderRoutingDefinitions.proposal.routes.RECEIVING_DATA.order,
        this.formControls.useAsReceivingData && !!this.formControls.useAsReceivingData.value
      );
    } else {
      this.setStepJump(orderRoutingDefinitions.proposal.stepNumber, orderRoutingDefinitions.proposal.routes.DELIVERY_CONTACT.order, false);
      this.setStepJump(orderRoutingDefinitions.proposal.stepNumber, orderRoutingDefinitions.proposal.routes.RECEIVING_DATA.order, false);
    }

    this.setStepJump(
      orderRoutingDefinitions.proposal.stepNumber,
      orderRoutingDefinitions.proposal.routes.PARTNER_DATA.order,
      this.documentCategory === DocumentCategory.CPF
    );

    return await this.dataStore.updateProposal({
      registrationDataPersonalInfo,
    });
  }

  protected updateFields(proposal: Proposal) {
    this.proposal = proposal;
    this.registrationDataPersonalInfoModel = proposal.registrationDataPersonalInfo;
    if (this.registrationDataPersonalInfoModel) {
      this.documentCategory = this.registrationDataPersonalInfoModel.documentCategory;
      this.successDocumentCall = this.registrationDataPersonalInfoModel.successDocumentCall;

      this.formControls.cpfCnpj.setValue(this.registrationDataPersonalInfoModel.cpfCnpj);
      this.formControls.notesCreditRisk.setValue(this.registrationDataPersonalInfoModel.notesCreditRisk);
      if (this.isCNPJ) {
        this.cnaeList = this.registrationDataPersonalInfoModel.cnaeList;

        if (this.configuration && this.configuration.serproValidationStrategy === SerproValidationStrategyEnum.PERSONAL_INFO_PAGE) {
          this.setAndCheckEmpty(this.formControls.socialReason, this.registrationDataPersonalInfoModel.socialReason);
          this.setAndCheckEmpty(this.formControls.openDate, this.registrationDataPersonalInfoModel.openDate);
          this.setAndCheckEmpty(this.formControls.constitutionForm, this.registrationDataPersonalInfoModel.constitutionForm);
        } else {
          this.formControls.socialReason.setValue(this.registrationDataPersonalInfoModel.socialReason);
          this.formControls.openDate.setValue(this.registrationDataPersonalInfoModel.openDate);
          this.formControls.constitutionForm.setValue(this.registrationDataPersonalInfoModel.constitutionForm);
        }

        this.formControls.companyFantasyName.setValue(this.registrationDataPersonalInfoModel.companyFantasyName),
          this.formControls.companyEmail.setValue(this.registrationDataPersonalInfoModel.companyEmail),
          (this.constitutionFormCode = this.registrationDataPersonalInfoModel.constitutionFormCode);
        this.formControls.companyMobileNumber.setValue(this.registrationDataPersonalInfoModel.companyMobileNumber);
      } else if (this.isCPF) {
        this.formControls.name.setValue(this.registrationDataPersonalInfoModel.name);
        this.formControls.motherName.setValue(this.registrationDataPersonalInfoModel.motherName);
        this.formControls.fixedNumberOne.setValue(this.registrationDataPersonalInfoModel.fixedNumberOne);
        this.formControls.fixedNumberTwo.setValue(this.registrationDataPersonalInfoModel.fixedNumberTwo);
        this.formControls.siteAddress.setValue(this.registrationDataPersonalInfoModel.siteAddress);
        this.formControls.politicalExposition.setValue(this.registrationDataPersonalInfoModel.politicalExposition);
        this.formControls.useAsDeliveryContact.setValue(this.registrationDataPersonalInfoModel.useAsDeliveryContact);
        this.formControls.useAsReceivingData.setValue(this.registrationDataPersonalInfoModel.useAsReceivingData);
        this.formControls.birthDate.setValue(this.registrationDataPersonalInfoModel.birthDate);
        this.formControls.personFantasyName.setValue(this.registrationDataPersonalInfoModel.personFantasyName);
        this.formControls.personEmail.setValue(this.registrationDataPersonalInfoModel.personEmail);
        this.formControls.personMobileNumber.setValue(this.registrationDataPersonalInfoModel.personMobileNumber);
        this.formControls.nationality.setValue(this.registrationDataPersonalInfoModel.nationality);
        this.formControls.rne.setValue(this.registrationDataPersonalInfoModel.rne);
        if (this.registrationDataPersonalInfoModel.politicallyExposedPerson) {
          this.formControls.pepName.setValue(this.registrationDataPersonalInfoModel.politicallyExposedPerson.name);
          this.formControls.pepRole.setValue(this.registrationDataPersonalInfoModel.politicallyExposedPerson.responsibility);
          this.formControls.pepGovernmentOrganization.setValue(
            this.registrationDataPersonalInfoModel.politicallyExposedPerson.governmentAgency
          );
        }
      }
      this.formControls.partnerId.setValue(this.registrationDataPersonalInfoModel.partnerId);
    } else {
    }

    this.setAndClearValidators(this.documentCategory);
  }

  protected createFields(): FdFieldConfigs {
    return {
      cpfCnpj: {
        label: 'CPF/CNPJ',
        controlName: 'cpfCnpj',
        type: InputType.TEL,
        mask: cpfCnpjMask,
        isNumber: true,
        maskCharsReplace: /[.\/ -]/g,
        messages: {
          required: 'Informe o CPF/CNPJ',
          invalidCnpj: 'CNPJ inválido',
          invalidCpf: 'CPF inválido',
        },
      },
      socialReason: {
        label: 'Razão Social',
        controlName: 'socialReason',
        maxLength: 200,
        messages: {
          required: 'Informe a razão social',
          invalid: 'razão social inválida',
        },
      },
      personFantasyName: {
        label: 'Nome Fantasia',
        controlName: 'personFantasyName',
        messages: {
          required: 'Informe o nome fantasia',
          invalid: 'Nome fantasia inválido',
        },
      },
      companyFantasyName: {
        label: 'Nome Fantasia',
        controlName: 'companyFantasyName',
        messages: {
          required: 'Informe o nome fantasia',
          invalid: 'Nome fantasia inválido',
        },
      },
      constitutionForm: {
        label: 'Forma de Constituição',
        controlName: 'constitutionForm',
        messages: {
          required: 'Informe a forma de constituição',
          invalid: 'forma de constituição inválida',
        },
      },
      openDate: {
        label: 'Data de fundação',
        mask: DateMask,
        controlName: 'openDate',
        maskCharsReplace: /[/\//]/g,
        messages: {
          required: 'Informe a data de fundação',
          invalid: 'Data inválida',
        },
      },
      companyEmail: {
        label: 'E-mail',
        controlName: 'companyEmail',
        messages: {
          required: 'Informe o e-mail',
          pattern: 'E-mail inválido',
        },
        maxLength: 50,
      },
      companyMobileNumber: {
        label: 'Celular',
        controlName: 'companyMobileNumber',
        mask: PhoneMask,
        maskCharsReplace: /[-( )]/g,
        messages: {
          required: 'Informe o celular',
          invalid: 'Celular inválido',
          pattern: 'Celular inválido',
        },
      },
      name: {
        label: 'Nome',
        controlName: 'name',
        messages: {
          required: 'Informe o nome completo',
          invalid: 'Informe o nome completo',
        },
      },
      birthDate: {
        label: 'Data de nascimento',
        controlName: 'birthDate',
        maskCharsReplace: /[/\//]/g,
        mask: DateMask,
        messages: {
          required: 'Informe a data de nascimento',
          invalid: 'Data inválida',
        },
      },
      motherName: {
        label: 'Nome da mãe',
        controlName: 'motherName',
        messages: {
          required: 'Informe o nome completo da mãe',
          invalid: 'Informe o nome e sobrenome',
        },
      },
      personEmail: {
        label: 'E-mail',
        controlName: 'personEmail',
        messages: {
          required: 'Informe o e-mail',
          pattern: 'E-mail inválido',
        },
        maxLength: 50,
      },
      personMobileNumber: {
        label: 'Celular',
        controlName: 'personMobileNumber',
        mask: PhoneMask,
        maskCharsReplace: /[-( )]/g,
        messages: {
          required: 'Informe o celular',
          invalid: 'Celular inválido',
          pattern: 'Celular inválido',
        },
      },
      fixedNumberOne: {
        label: 'Telefone 1',
        controlName: 'fixedNumberOne',
        mask: PhoneMask,
        maskCharsReplace: /[-( )]/g,
        messages: {
          required: 'Informe o telefone',
          invalid: 'Telefone inválido',
          pattern: 'Telefone inválido',
        },
      },
      fixedNumberTwo: {
        label: 'Telefone 2',
        controlName: 'fixedNumberTwo',
        mask: PhoneMask,
        maskCharsReplace: /[-( )]/g,
        messages: {
          required: 'Informe o telefone',
          invalid: 'Telefone inválido',
          pattern: 'Telefone inválido',
        },
      },
      siteAddress: {
        label: 'Site',
        controlName: 'siteAddress',
        messages: {
          required: 'Informe o site',
          invalid: 'Formato inválido',
        },
      },
      nationality: {
        label: 'Nacionalidade',
        items: [],
        controlName: 'nationality',
        searchPlaceholder: 'Buscar',
        searchable: true,
        required: true,
        messages: {
          required: 'Informe a nacionalidade',
          invalid: 'Nacionalidade inválida',
          pattern: 'Nacionalidade inválida',
        },
      },
      rne: {
        label: 'Registro Nacional de Estrangeiros (RNE)',
        controlName: 'rne',
        mask: RneMask,
        maskCharsReplace: /[-]/g,
        messages: {
          required: 'Informe o RNE',
          invalid: 'Formato inválido',
        },
      },
      politicalExposition: {
        controlName: 'politicalExposition',
        items: [],
        messages: {
          required: 'Informe se a pessoa é politicamente exposta',
        },
      },
      useAsDeliveryContact: {
        controlName: 'useAsDeliveryContact',
        items: [],
        messages: {
          required: 'Informe uma opção',
        },
      },
      useAsReceivingData: {
        controlName: 'useAsReceivingData',
        items: [],
        messages: {
          required: 'Informe uma opção',
        },
      },
      pepName: {
        label: 'Nome',
        controlName: 'pepName',
        messages: {
          required: 'Informe o nome completo',
          invalid: 'Informe o nome completo',
        },
      },
      pepRole: {
        label: 'Cargo',
        controlName: 'pepRole',
        messages: {
          required: 'Informe o cargo',
          invalid: 'Informe o cargo',
        },
      },
      pepGovernmentOrganization: {
        label: 'Órgão do Governo',
        controlName: 'pepGovernmentOrganization',
        messages: {
          required: 'Informe o órgão do governo',
          invalid: 'Informe o órgão do governo',
        },
      },
      partnerId: {
        label: 'Id do Cliente no Parceiro',
        controlName: 'partnerId',
        messages: {
          required: 'Informe o Id do Cliente no Parceiro',
          invalid: 'Informe o Id do Cliente no Parceiro',
        },
        maxLength: 20,
      },
      notesCreditRisk: {
        label: 'Observações da proposta para Crédito e Risco',
        controlName: 'notesCreditRisk',
        maxLength: 150,
        tooltipMessage: 'O preenchimento deste campo deve ser alinhado previamente com Crédito e Risco.',
      },
    };
  }
}

export enum SerproResponseCodes {
  VALID = 0,
  INVALID = 1,
  CONTRACT_WITHOUT_VALIDATION = 86,
}

export enum SerproDocumentErrorCodes {
  IRREGULAR_DOCUMENT_SITUATION = 1,
  INVALID_NAME_OR_SOCIAL_REASON = 2,
  INVALID_BIRTH_DATE_OR_OPEN_DATE = 3,
  INVALID_CNAE = 4,
  INVALID_PARTNER_DATA = 5,
  MAX_ATTEMPTS_EXCEEDED = 99,
}
