<h4 class="fd-subtitle">Indique o(s) produto(s) e a(s) quantidade(s):</h4>
<form *ngIf="formGroup" [formGroup]="formGroup">
  <div formArrayName="technologyForms">
    <form [formGroupName]="i" *ngFor="let childFormGroup of technologyFormsControls; let i = index">
      <div fxLayout="column" fxLayoutAlign="center stretch">
        <div>
          <fd-select
            (loadItemsFn)="loadTechnologies(i)"
            [parentForm]="childFormGroup"
            (selectionChange)="setPrice(i)"
            [field]="fieldsArray[i].technology"
          >
          </fd-select>
        </div>
        <div fxLayoutGap="20px">
          <div fxFlex="50">
            <fd-select
              (loadItemsFn)="getAmount()"
              [parentForm]="childFormGroup"
              [field]="fieldsArray[i].amount"
              (selectionChange)="getValuesRange($event)"
            >
            </fd-select>
          </div>
          <div fxFlex="50" *ngIf="!isSales">
            <fd-value-editor
              [disabled]="hasSplitTech()"
              (openDialogFn)="openValueEditorModal($event, i)"
              [parentForm]="childFormGroup"
              [field]="fieldsArray[i].price"
              >10</fd-value-editor
            >
          </div>
          <div fxFlex="50" *ngIf="isSales">
            <fd-value-editor
              [disabled]="hasSplitTech()"
              (openDialogFn)="openValueEditorModal($event, i)"
              [parentForm]="childFormGroup"
              [field]="fieldsArray[i].price"
              >10</fd-value-editor
            >
          </div>
        </div>
        <div>
          <fd-button
            size="thin"
            *ngIf="shouldShowRemoveButton(i)"
            fdSecondary
            [content]="removeTechnologyButtonHTML"
            (trigger)="removeTechnologyForm(i)"
          >
          </fd-button>
        </div>
      </div>
    </form>

    <fd-button *ngIf="showAddButtonOrEdi()" size="thin" fdTertiary [content]="addTechnologyButtonHTML" id="btnAdd" (trigger)="addForm()">
    </fd-button>

    <div class="checkbox-container">
      <mat-checkbox
        *ngIf="showAddButtonOrEdi()"
        (change)="onCheckboxChange(ediItem, $event)"
        [value]="ediItem.value"
        [checked]="ediItem.selected"
        class="fd-checkbox"
        [disabled]="hasCloverTech()"
        [id]="ediItem.value"
        >{{ ediItem.label }}
      </mat-checkbox>
      <fa-icon
        *ngIf="hasCloverTech()"
        matTooltipClass="fd-input__tooltip-dialog"
        class="tooltip-icon"
        icon="info-circle"
        size="1x"
        mat-button
        [matTooltip]="cloverToolTipText()"
        #tooltip="matTooltip"
        (click)="showTooltipAndStopPropagation()"
      ></fa-icon>
    </div>

    <div *ngIf="hasCloverTech()">
      <p>
        <i>
          “Ao contratar a tecnologia Clover escolhida, serão habilitados automaticamente para o seu cliente o Aplicativo GPAG, os Serviços
          de Captura e Roteamento, Tap on phone e, sujeito à aprovação da Fiserv, o serviço de Link de pagamento. Para estes serviços, não
          haverá cobrança adicional ao aluguel da tecnologia Clover acima indicada."
        </i>
      </p>
    </div>

    <div class="checkbox-container">
      <mat-checkbox
        *ngIf="showSplitPaymentCheck()"
        (change)="onCheckboxChangePaymentSplit(paymentSplitItem, $event)"
        [value]="paymentSplitItem.value"
        [checked]="paymentSplitItem.selected"
        class="fd-checkbox"
        [id]="paymentSplitItem.value"
        >{{ paymentSplitItem.label }}
      </mat-checkbox>
    </div>
    <div class="checkbox-container">
      <mat-checkbox
        *ngIf="hasECommerce && hasECommerceExceptionCnaeSelected"
        (change)="onCheckboxChange(paymentLinkItem, $event)"
        [value]="paymentLinkItem.value"
        [checked]="paymentLinkItem.selected"
        class="fd-checkbox"
        [id]="paymentLinkItem.value"
        >{{ paymentLinkItem.label }}
      </mat-checkbox>
    </div>
    <fd-input *ngIf="hasECommerce && hasECommerceExceptionCnaeSelected" [parentForm]="formGroup" [field]="fields.url"> </fd-input>
    <fd-input *ngIf="hasSplitTech()" [parentForm]="formGroup" [field]="fields.ecMasterDocument" (blur)="validateECMaster($event)">
    </fd-input>
  </div>

  <fd-button fdSecondary [content]="'cancelar'" id="btnCancel" (trigger)="back()"> </fd-button>
  <fd-button [content]="'avançar'" id="btnNext" (trigger)="clickBtnNext()"> </fd-button>
</form>
